.generate-story-btn {
  padding-top: 20px;
  text-align: center;
}

.input_field_placeholder {
  position: absolute;
  top: 20px;
  pointer-events: none;
  padding: 9px 10px;
  color: rgba(33, 37, 41, 0.25);
  font-size: 14px;
}

.input_field_placholder_align_en {
  left: 0;
  padding-left: 12px;
  font-size: 15px;
}

.input_field_placholder_align_he {
  right: 0;
}

.piece_container_clicked
{
    /*border: 1px dashed blue;*/
    /*background-color: blue;*/
}

.box
{
    cursor: move;
}

.info_delete_for_text {
    position: absolute;
    bottom: 7px; /* Aligns with the top edge of the container */
    right: -30px; /* Adjust this value as needed to position the icon to the right of the textbox */
    color: white;
    background-color: #8A459B;
    font-size: 22px;
    cursor: pointer;
    padding: 5px;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Make sure it's above other elements */
}

.info_delete_for_text.left {
    left: -30px;
}
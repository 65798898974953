.form_container {
  margin: auto;
  text-align: center;
  padding: 10px;
  /*width: 500px;*/
  width: 100% !important;
}
.form-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_form {
  /*margin-top:50px;*/
  padding-left: 10px;
  padding-right: 10px;
}
.page_title {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 10px;
  /* margin-right:35px; */
}
.slider_range {
  float: right;
}
.slider_label {
  float: left;
}
.submit_button {
  float: right;
}

.img {
  text-align: center;
  width: 150px;
  height: 150px;
  margin-bottom: 2rem;
}
/* eslint-disable-next-line */
.image-upload_preview {
  width: 200px;
  height: 200px;
  border: 1px solid #222;
  /* border-radius:10%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  color-adjust: pointer;
  cursor: pointer;
  /*margin-left:150px;
    margin-right:180px;*/
}

.image-upload_preview:hover .hidden_icon {
  display: block;
  color: grey;
}
.image-upload_preview .hidden_icon {
  position: absolute;
  display: none;
}

.image-upload_preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload-button-en {
  height: 40px;
  width: 40px;
  margin-right: 17px;
  color: #000;
}
.image-upload-button-en:hover {
  color: #8a459b;
}

.image-upload-button-he {
  height: 40px;
  width: 40px;
  margin-left: 13px;
  color: #000;
}
.image-upload-button-he:hover {
  color: #8a459b;
}

a.edit_link:link {
  color: #8a459b;
  text-decoration: none;
}
a.edit_link:visited {
  color: #8a459b;
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .container {
    /*width: 100% !important;*/
    margin-right: 5px;
    margin-left: 1px;
  }
}

/* Default LTR styles */
.search-container {
  position: relative;
  margin-left:35px;
}

.search-input {
  width: 100%;
  padding: 7px;
  padding-left: 35px; /* Icon space */
  border: 2px solid #8A459B;
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
}

.search-input::placeholder {
  color: rgb(177, 50, 213);
  text-shadow: 0 0 1px rgb(159, 49, 199);
  opacity: 0.7;
}

.search-input:focus {
  border-radius: 10px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  fill: #84149a;
}

/* RTL specific styles */
.rtl .search-input {
  padding-right: 35px; /* Adjust for RTL */
  padding-left: 7px;  /* Reset left padding */
}

.rtl .search-icon {
  right: 10px; /* Adjust for RTL */
  left: auto;  /* Reset left position */
}

.rtl .search-container {
  margin-left:0px;
  margin-right:35px;
}

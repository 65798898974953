
 @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface:wght@400&display=swap');

.page {
    /*overflow-x: auto;
    overflow-y: hidden;*/
    text-align: justify;
    background-color: #f8f2fa; /*#f9f8fa*/
    border-top: 1px solid #8A459B;
}


.page_indicator {
    font-size: 17px;
}

body.modal-open> :not(.modal) {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
}

.action-buttons {
    text-align: center;
    width: 520px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    height:35px;
}
.btn.disable:hover {
    color: #b1b1b1 !important;
    border-color: #b1b1b1 !important;
}

.book-buttons .disable:hover{
    color: #b1b1b1 !important;
    border-color: #b1b1b1 !important;
}
.btn-primary.disable:hover{
    background-color: white !important;
}
.action-btn:hover {
    color: #8A459B;
}
.action-btn.disabled:hover {
    color: #b1b1b1;
}

.browse_back_btn {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-right: 10px;
}

.disabled {
    color: #b1b1b1;
    border-color: #b1b1b1;
}
.btn.disable {
    color: #b1b1b1;
    border-color: #b1b1b1 !important;
}
.btn.disable:hover {
    color: #b1b1b1;
    border-color: #b1b1b1 !important;
}

.add_remove_buttons {
    float: right;

}

/* .add_page{
    font-size:20px;
    float:right;

} */
.add_page {}

.add_page_btn {
    float: right;
    font-size: 17px;
    margin-top:4px;
}

.add_page:hover {
    color: #8A459B;
}

.remove_page_btn {
    float: left;
    margin-top:4px;
    font-size: 17px;
}

.remove_page_btn:hover {
    color: #8A459B;
}

.remove-page-icon {
    margin-right: 5px;
    margin-bottom: 3px;
}

.add-page-icon {
    margin-left: 5px;
    margin-bottom: 1px;
}

.page_browse_btn {
    box-shadow: none;
    position: relative;
}

.page_browse_btn_forward {
    /*margin-left: 15px;*/
    margin-left: 10px;
}

.bookDetailsRow {
    /*float:left;*/
    margin-top: 3px;
    margin-left: 10px;
}

.bookName {
    resize: none;
    border: 2px solid transparent;
    text-align: left;
    background-color: hsl(0, 0%, 100%);
    margin-top: 10px;
    margin-left: 70px;
    color: #8A459B;
    font-size: 20px;
    width:350px;
    /*width:100%;*/
}

.bookName:hover{
    background-color:#eddff0;
}

.bookName input:focus{
    border:1px solid green !important;
}

.book-buttons{
    float: right;
}

.book-buttons .btn{
    background-color: #8A459B;
    border-color: #8A459B;
    margin-left: 10px;
    /* padding-top: 9px; */
}

.book-buttons .btn:hover{
    background-color: #eddff0;
    border-color: #8A459B;
    color:#8A459B
}

.book-button {
    width: 120px;
    height: 40px;
    margin-top: 10px;
    background-color: #8A459B;
    color: white;
    border: none;
}

.book-buttons .btn .book-btn-icon {
    font-size: 1.5em;
    margin-left: 10px;
    margin-bottom: 4px;
    /*margin-bottom: 3px;*/
}

.publish-btn{
    margin-left:10px;
}

@media only screen and (max-width: 950px) {
    .button-caption {
        display: none;
    }

    .book-buttons .btn .book-button-text {
        display: none;
    }

    .bookName {
        display: none;
    }

    /*page navigation buttons*/
    .action-buttons {
        position: absolute;
        top: 10px;
        width: 120px;
        z-index: 2;
        margin-left: 10px;
    }

    .action-btn {
        font-size: 20px;
        margin-top:3px;
    }

    .page_indicator{
        margin-top:3px;
        font-size: 15px;
    }

    .browse_back_btn{
        margin-right:2px;
    }

    .page_browse_btn_forward{
        margin-left:2px;
    }


    /*publish and export buttons */
    
    .book-buttons{
        position: absolute;
        float: right;
        right:10px;
        z-index: 2;
        margin-top:10px;
    }

    .book-buttons .btn{
        height: 35px;
        width: 30px;
        padding-left:11px;
    }

    .book-buttons .btn .book-btn-icon{
        /*padding-right:2px;*/
        margin-left: -10px;
        margin-top: -9px;
    }

    /*.book-button {
        z-index: 2;
        width: 35px;
        position: absolute;
        width: 40px;
        right: 30px;
        padding-right: 12px;
        background-color: #8A459B;
        color: white;
        margin-top: 10px;
        float: none;
    }

    .publish-btn{
        position: absolute;
        right: -40px;
        padding-right: 12px;
    }

    .export-btn{
        position: absolute;
        right: 10px;
        padding-right: 12px;
    }

    .book-button-icon {
        font-size: 1.2em;
        margin-left: 5px;
        margin-bottom: 3px;
    }*/

}
.modal-container {
  width: 100%;
  padding: 5px;
}


@media only screen and (max-width: 480px) {
    .book-buttons{
        /*right:-120px;*/
    }
}
#main_page {
    position: relative;
    height: 100%;
    /* width: 100%; */
}

#main_page_he {
    position: relative;
    height: 100%;
    font-family: 'Heebo', sans-serif;
    /* width: 100%; */
}



.sidebar {
    position: absolute;
    width: 98px;
    height: 722px;
    left: 4px;
    padding-top: 80px;
    background: #FBFBFB;
    box-shadow: 3px -1px 31px -1px rgba(0, 0, 0, 0.09);
}

.board {
    width: 99%;
    /* height:600px; */
    min-width: 82.5%;
    float: left;
    border-left-width: 4px;
    border-left-style: solid;
    border-image:
        linear-gradient(to left, black, white) 1 30%;
    padding-left: 3%;
}

.clearfix {
    clear: both
}


@media only screen and (max-width: 800px) {

    /* .icon_caption{
        display: none;
    } */
    .sidebar {
        z-index: 2;
        background: none;
        box-shadow: none;
        padding-top: 200px;
    }

}
    
    .add_button{
        cursor:pointer;
        border:none;
        font-size:18px;
        background-color:#8A459B;
        outline:none;
        padding-bottom:6px;
        margin-top:5px;
    }
    .add_button:hover{
        background-color:#8A459B;
    }

    .border_row{
        /*margin-top: 10px;
        border-bottom: 1px solid var(--ds-border-neutral,var(--ds-border-neutral,rgba(217, 148, 238, 0.8)));
        margin-bottom: 10px;*/
    }

    h5{
        padding-bottom:5px;
        font-size: 13px;
        font-weight: 600;
        color: var(--ds-text-lowEmphasis,var(--local-dynamic-table-text-color));
        margin-top: 10px;
        border-bottom: 1px solid var(--ds-border-neutral,var(--ds-border-neutral,rgba(217, 148, 238, 0.8)));
        margin-bottom: 10px;
    }

    .row{
        font-size:14px;
        margin-bottom:10px;
    }

    AiFillFilePdf{
        padding:5px
    }

    .inner_col{
        margin-left:1px;
    }

    @media only screen and (max-width: 800px){
        .last_login_col{
            display: none;
        }
    }
    
    
  
.handle{
    overflow: none;
}

.study_piece{
    position: absolute;
}
@media only screen and (max-width: 400px){
    #book-page{
        width: 100% !important;
        margin: 0!important;
        padding: 0!important;
        -webkit-transform: scale(0.65, 0.65) !important; 
        transform-origin: top left!important; 

    }
    .handle{
       max-width: 100% !important;
       padding: 0!important;
       margin: 0!important;
       overflow: inherit !important;
       margin-top: 15%!important;
    }
    .board{
        padding-left: 0!important;
        min-width: 100%!important;  
       left: 30%!important;
    }
    .sidebar{
        /* visibility: hidden; */
    }
    .study_piece{
        margin-left: 15px!important;
    }
}
@media only screen and (max-width: 750px){
    #book-page{
        width: 100% !important;
        margin: 0!important;
        padding: 0!important;
        -webkit-transform: scale(0.65, 0.65) !important; 
        transform-origin: top left!important; 
        margin: 8%!important;

    }
    .handle{
       max-width: 100% !important;
       padding: 0!important;
       margin: 0!important;
       overflow: inherit !important;
       margin-top: 15%!important;
    }
    .board{
        padding-left: 0!important;
        min-width: 100%!important;  
       left: 30%!important;
    }
    .sidebar{
        /* visibility: hidden; */
    }
    .study_piece{
        margin-left: 15px!important;
    }

}
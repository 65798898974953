.btn-circle {
    width:30px;
	height:30px;
    font-size: 16px;
    line-height: 1.42857;
    padding-bottom: 8px;
    font-weight: var(--wbu-font-weight-regular);
}

.range
{
    width:50px;
    vertical-align: middle;
}

.btn-range
{
    height:30px;
    padding-bottom:3px;
}

.buttonsRow{
    margin-bottom: 8px;
    margin-top:2px;
    display: flex;
    position:absolute;
    float: left;
    opacity: 80%;
    z-index:1; 
}

.image-buttonsRow {
    left: 30px;
  }
  
.hoverable :hover{
    color: rgb(13, 110, 253);
    /*border: 0.2px solid grey;*/
}

.btn-ellipsis {
	height:30px;
    font-size: 15px;
}

.float{
    border-radius: 15px;
    border: 0.2px solid grey;
    margin-left:2px;    
	border-radius:50px;
	text-align:center;
    font-family: var(--wbu-font-stack,HelveticaNeueW01-55Roma,HelveticaNeueW02-55Roma,HelveticaNeueW10-55Roma,Helvetica Neue,Helvetica,Arial,メイリオ,meiryo,ヒラギノ角ゴ pro w3,hiragino kaku gothic pro,sans-serif);
    -webkit-font-smoothing: antialiased;
    color: #2d3034;
    background: rgb(255, 255, 255);
    box-shadow: 0 1px 18px 0 rgb(0 0 0 / 8%), 0 6px 10px 0 rgb(0 0 0 / 10%), 0 3px 5px -1px rgb(0 0 0 / 15%), 0 0 4px 0 rgb(0 0 0 / 10%);
}

.placholder:hover{
    /*border:6px solid blue;*/
}

.image_edit_hover{
    position: absolute;
    top:10px;
    left:10px;
    color: white;
    background-color: #8A459B;
    font-size: 30px;
    cursor:pointer;
    padding:5px;
    width: 1em;
    height: 1em;
    border-radius: 20%;
}

.image_edit_ai{
    top:48px;
    padding:3.5px;
}

.private_info_pencil{
    top:10px;
}

.private_info{
    position: absolute;
    top:10px;
    left:10px;
    color: #8A459B;
    font-size: 25px;
    cursor:default;
}

.private_overlay{
    background-color: red;
}

.image_edit_mode
{
    /*border: 1px dashed blue;*/
    /*padding:5px;*/
    background: transparent;
    background-color: rgb(300, 300, 300);

}

.image_read_mode{
    cursor: move !important;
}

.placholder
{
    cursor:default !important;
} 

.info_pencil_for_text {
    position: absolute;
    top: 5px; /* Aligns with the top edge of the container */
    right: -30px; /* Adjust this value as needed to position the icon to the right of the textbox */
    color: white;
    background-color: #8A459B;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    border-radius: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Make sure it's above other elements */
}
.info_pencil_for_text.left {
    position: absolute;
    left: -30px;
    /* Rest of your left-positioned styling */
}

.info_pencil_for_text.right {
    position: absolute;
    right: -30px;
    /* Rest of your right-positioned styling */
}
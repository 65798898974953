.image_card:hover{
    border:1px solid #8A459B;
}
.image_card{
    cursor: pointer;
    border:1px solid white;
}


.images-body {
    overflow-y: auto;
    overflow-x: hidden; /* Hides horizontal scrollbar */
  }
  
/* For Webkit browsers */
.images-body::-webkit-scrollbar {
  width: 14px; /* Optimal width for visibility and aesthetics */
  display: block;
  overflow-y: auto;
  overflow-x: hidden; /* Hides horizontal scrollbar */
}

.images-body::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Light base color for the track */
  background-image: linear-gradient(45deg, rgba(255,255,255,0.2) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, transparent 75%, transparent); /* Subtle hatching pattern */
  background-size: 20px 20px; /* Size of the pattern */
  box-shadow: inset 0 0 10px rgba(0,0,0,0.05); /* Lighter inset shadow for a subtle depth */
}

.images-body::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #c895f5, #8f5dc2); /* Diagonal gradient for a dynamic look */
  border-radius: 14px; /* Fully rounded ends for the thumb */
  box-shadow: 0 2px 4px rgba(0,0,0,0.15); /* Soft shadow for a floating effect */
  transition: background 0.3s, box-shadow 0.3s; /* Smooth transition for hover and active states */
}

.images-body::-webkit-scrollbar-thumb:hover,
.images-body::-webkit-scrollbar-thumb:active {
  background: linear-gradient(135deg, #a16fc5, #8764c0); /* Lighter gradient for hover/active effect */
  box-shadow: 0 2px 4px rgba(0,0,0,0.25); /* Slightly deeper shadow for hover/active effect */
}

.images-body::-webkit-scrollbar-corner {
  background-color: transparent; /* Ensures the corner is seamless */
}

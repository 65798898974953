.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: "Lato", sans-serif !important;
}

.rtl {
  font-family: "Heebo", sans-serif !important;
}

input[type="checkbox"] {
  background-color: #eddff0 !important;
  border-color: #8a459b !important;
}

input[type="checkbox"]:checked {
  background-color: #8a459b !important;
  border-color: #eddff0 !important;
}

.btn-primary:hover {
  background-color: #8a459b !important;
  border-color: #eddff0 !important;
  /*margin-left: 10px;*/
  color: white !important;
}

.btn-primary {
  background-color: white !important;
  border-color: #8a459b !important;
  color: #8a459b !important;
  border: 2px solid !important;
}

.form_container {
  text-align: center;
  padding-left: 20% !important;
  padding-right: 20% !important;
  width: 100%;
}

.validation_error {
  color: "#dd2c00";
  margin-left: "5px";
}

.categoryHeader {
  margin-top: "5px";
  font-weight: "900";
}
.categoryHeaderHe {
  margin-top: "5px";
  font-weight: "900";
  font-family: "Heebo", "Noto Naskh Arabic", sans-serif !important;
}

.add_story_col:hover {
  background-color: #eddff0;
}
.add_story_col.disabled:hover {
  background-color: white;
}

.link_btn a:link {
  text-decoration: none;
}
.link_btn a:visited {
  text-decoration: none;
}
.link_btn a:hover {
  text-decoration: none;
}
.link_btn a:active {
  text-decoration: none;
}
.link_btn a {
  color: #8a459b !important;
}
.disabled {
  color: #b1b1b1 !important;
}

.link_btn {
  padding-top: 4px;
  color: #8a459b !important;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none !important;
}

input[type="text"]:focus,
input[type="password"]:focus input[type="number"]:focus,
input[type="checkbox"]:focus,
.form-select:focus,
.form-control:focus,
select:focus,
textarea:focus,
.btn:focus,
button:focus {
  box-shadow: 0 0 5px rgba(217, 148, 238, 1) !important;
  /*padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;*/
  border: 1px solid rgba(217, 148, 238, 1) !important;
}

h4 {
  font-weight: 900 !important;
  margin-top: 40px !important;
}

.categoryHeader {
  margin-top: 5px;
  font-weight: 900;
}
.categoryHeaderHe {
  margin-top: 5px;
  font-weight: 900;
  font-family: Heebo;
}

/* RTL specific styles */
[dir="rtl"] .form-floating {
  direction: rtl;
  text-align: right;
}

[dir="rtl"] .form-floating label {
  text-align: right;
}

[dir="rtl"] .form-floating > label {
  right: 0;
  transform-origin: 100% 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  margin-top: -2px !important;
}
[dir="rtl"] .form-floating > .form-control-plaintext ~ label,
.form-floating.right-to-left > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(-0.15rem);
}

[dir="rtl"] .form-select {
  background-position: left 0.5rem center;
}

[dir="rtl"] select {
  background-position: left 0.5rem center;
  padding-right: 10px;
}

.custom-control {
  border: 1px solid lightgray;
  box-shadow: none;
}

.custom-control:hover {
  border-color: #8a459b !important;
}

/* placeholder */
[dir="rtl"].custom-placeholder {
  text-align: rtl;
}

/* option */
.custom-option {
  color: black;
  background-color: white;
}

[dir="rtl"] .custom-option {
  text-align: rtl;
}

.custom-option:hover {
  background-color: #eddff0;
  color: black;
}

.custom-option.selected {
  background-color: #8a459b;
  color: white;
}

/* singleValue */
.custom-single-value {
  /* Add any specific styles if needed */
}

/* menuList */
.custom-menu-list {
  background-color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.commoncheckbox {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 5px;
}

.load_spinner{
  color: #8a459b;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashed_frame {
    height: 100%;
    width: 100%;
    padding:1px;
}

.story_body_main{
    font-weight: bold;
    color:blue;
}
.story_body_sub{
    padding-left:12px;
    word-wrap: break-word;
    overflow: hidden;
}

@media only screen and (max-width: 800px) {
    .story_body_sub{
        font-size: 12px;
    }
}



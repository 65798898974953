.login_header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  padding-bottom: 2px;
}

.loginContainer {
  padding-top: 15px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Optionally, add spacing between checkbox and text */
.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 14px;
  text-align: right;
}
.checkbox-wrapper .form-check {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.checkbox-wrapper .form-check-input {
  margin-left: 10px;
  margin-right: 10px;
}

.login_terms_link {
  /*text-decoration:underline #efebf0;*/
  text-decoration: none;
}

.read_privacy_alert {
  color: #dd2c00;
  font-size: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 16px;
  position: absolute;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.alertContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the parent container has a defined height */
}

.button-container .record-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid;
    background-color: white;
    color: #007bff !important;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.button-container .record-button.start-recording {
    color: #007bff !important;
    border-color: #007bff !important;
}

.button-container .record-button.stop-recording {
    color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.button-container .record-button:hover {
    background-color: white !important;
}

.button-container .record-button .icon {
    font-size: 22px;
    margin-left: 15px; /* Default margin for LTR */
}

/* RTL support */
.button-container .record-button.rtl .icon {
    margin-left: 10px;
    margin-right: 0;
}

.button-container .record-button.ltr .icon {
    margin-left: 0;
    margin-right: 10px;
}

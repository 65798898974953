
.sideBarIcon{
    /* display:inline-block; */
    /* margin-bottom: 20px; */
    font-size: 30px;
    color: black;
}

.sidebar{
    padding-left:10px;
    padding-right:3px;
}

.icon_caption .text_caption{
    margin-top: 5px;
    font-size: 15px;
    text-align: center;
}
.sideBarContainer .icon_caption{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-origin: padding-box;
}

.sideBarContainer .icon_caption:hover{
background: #8A459B;
color: white;
}

.sideBarContainer .icon_caption.disabled:hover{
    background: white;
    color: #b1b1b1;
    }

.sideBarIcon.SymbolIcon
{
    background-size: contain;
    width:1em;
    height:1em;
}

.background_icon{
    font-size: 28px;
}

.symbol
{
    background-image: url('happyface_bw.svg');
    background-size: contain;
    width:1.2em;
    height:1.2em;
    font-weight: 800;
    margin-left:35%
}

.symbolImg{
    width:1.5em;
    height:1.5em; 
}

body::after{
    position:absolute; 
    width:0; 
    height:0; 
    overflow:hidden; 
    z-index:-1; 
    content:url('happyface_bw_blue.svg') 
 }

.symbol:hover, .sideBarContainer:hover .symbol
{
    background-image: url('happyface_bw_blue.svg');
}

.sideBarIcon.textAreaIcon
{
    /*font-size: 35px !important;*/
}

.symbolFont{
    font-size: 38px;
    font-weight: 100;
}

.icon_caption{
    font-size: 22px;
    display: inline-block;
    text-align: center;
    color:black;
    line-height: 19px;
    
    
    /* font-weight: 500; */
}
.disabled {
    color: #b1b1b1;
}


/* hide icon captions */
@media only screen and (max-width: 800px){
    /* .icon_caption{
        display: none;
    } */
    .symbol{
        margin-left:25%;
    }
    .sideBarContainer .icon_caption{
        background: #FBFBFB;
        width: 50px;
        height: 45px;
        padding-top: 12px;
        padding-bottom: 12px;
        /*border: 1px solid black;*/
        margin-bottom: 5px;
    }
    .sideBarIcon{
        font-size: 15px;
    }
    .icon_caption .text_caption{
        display: none;
    }

}

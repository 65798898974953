.text_editor_wrapper_edit_mode{
    border-right: 1px solid #8A459B;
    border-left: 1px solid #8A459B;
    border-bottom: 1px solid #8A459B;
    /*width:430px;*/
    cursor:text;

    /*enable resize*/
    resize: horizontal;
    overflow: auto;
    height: 100%;
    width:100%;
    /*width: 430px;*/
}

.text_editor_wrapper{
    border: none;
    cursor: move;
    resize: none;
}

.public-DraftEditorPlaceholder-inner{
    font-size:12px;
}

.text_edtior_buttonsRow
{
    left:-25px;
    padding-top:2px;
    z-index: 3;
}

.public-DraftStyleDefault-rtl {
    font-family: 'Heebo', sans-serif;
}

/*.public-DraftStyleDefault-rtl{
    font-family: 'Heebo', sans-serif !important;
}*/

.public-DraftEditor-content{
    direction: rtl !important;
}

.DraftEditor-root {
    font-family:'Lato', sans-serif !important;
    /*font-family: 'Heebo', sans-serif !important;*/
  }

.toolbar{
    position:absolute;
    width: 430px !important;
    top:5;
    left:90;
    padding:0;
    border:none;
    z-index: 2;
    opacity: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top:-10px;
}

.toolbar_rtl{
    left:90;
}

.editor{
    /*min-width:50px;*/
    min-width: 50px;
    max-width: 500px;
    line-height: 26px !important;
    font-size:20px;
    /*font-family: 'Heebo', sans-serif;*/
    /*enable resize*/
    height: 100%;
    width: 100%;
    /*width: 430px;*/
}

.toolbar_fontsize_dropdown{
    z-index: 5;
}

@media only screen and (max-width: 800px){
    .text_editor_wrapper_edit_mode{
        /*width:330px;*/
        cursor:text;
    }
    .editor{
        /*width:330px*/
    }
}
    
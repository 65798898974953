.contact_container {
    /*margin: auto;*/
    text-align: center;
    /*width: 50% !important;*/
    padding-left:20% !important;
    padding-right:20% !important;
    width:100%;
  }
  .content_box {
    height: 80px;
  }
  .non_content_box {
    height: 40px;
  }
  .contact_form {
    margin-top: 20px;
  }
  .post_submit_container{
      margin-top:80px
  }

  .post_submit_header{
      margin-bottom:30px;
      white-space: pre-line;
  }

  .contact_form_header{
      margin-bottom:10px;
      font-size:16px;
      color:rgba(107,114,128,var(--tw-text-opacity));
      white-space: pre-line;
  }

  input{
      margin-bottom: 12px;
  }

  .ozzy-at-bett-container{
    vertical-align: middle;
  }

  .bettLogo{
      width:90px;
      margin-top: 10px;
  }

  .ozzystory-at-bett{
    font-size: 25px;
    vertical-align:-5px;
  }


  @media only screen and (max-width: 1000px) {

    .contact_container {
        padding-left:5% !important;
        padding-right:10% !important;
      }

}
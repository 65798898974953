.trash-icon
{
    font-size:24px;
    color:#8A459B;
    background: transparent;
    padding: 2px;
    cursor: pointer;
    margin-left:10px;
    margin-right:10px;
}

.trash-icon:hover{
    box-shadow: 0 0 5px #8A459B;
}

.toast-loading{
    margin-bottom: 20px;
    color:#8A459B;
    /* border: 1px solid #8A459B; */
    z-index: 4;

}
.body_text{
    font-weight: 500;
    font-size:16px;
}
.search_input {
  border: none;
  width: 89%;
  margin-top: 5px;
}

.search_input:focus {
  outline: none;
}

.search_row_container {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.searchContainer {
  border: 1px solid #8a459b;
  border-radius: 0.5rem;
  /*margin-top:25px;
    margin-bottom:25px;*/
  min-height: 40px;
  height: 100%;
  margin-left: 13px;
  margin-right: 13px;
}

.icon {
  font-size: 17px;
  margin-bottom: 4px;
  color: #8a459b;
  height: 100%;
  margin-left: 13px;
}

.searchIcon {
  font-size: 17px;
  margin-left: 10px;
  margin-bottom: 4px;
  color: #8a459b;
  margin-right: 5px;
}

.add_story_col:hover {
  background-color: #eddff0;
}
.add_story_col.disabled:hover {
  background-color: white;
}

.add_story_col a:link {
  text-decoration: none;
}
.add_story_col a:visited {
  text-decoration: none;
}
.add_story_col a:hover {
  text-decoration: none;
}
.add_story_col a:active {
  text-decoration: none;
}
.add_story_col a {
  color: #8a459b !important;
}

.add_story_col {
  padding-top: 4px;
  color: #8a459b !important;
  font-weight: 700;
  margin-right: 10px;
  cursor: pointer;
}

.add_story_btn {
  font-size: 30px;
  margin-right: 3px;
}

.add_story_btn_rtl {
  font-size: 28px;
  margin-right: 0px;
  margin-left: 3px;
}
.search_container_rtl {
  font-family: "Heebo", "Noto Naskh Arabic", serif !important;
}
@media only screen and (max-width: 800px) {
  .new_story_caption {
    display: none;
  }
  .search_container_rtl {
    padding-right: 20px !important;
  }

  .search_input {
    width: 80%;
  }
}

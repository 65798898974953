
/* .iframe-container {
  overflow: hidden;
  padding-top: 100%;
  position: relative;
    width: 100%;
}
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width:100%;
   bottom: 0;
  right: 0;
}
 */

.iframe-container {
position: relative;
  padding-bottom: 100%;
  padding-top: 100%;
  height: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
    
}
.iframe-container iframe {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

::-webkit-scrollbar {
    display: none;
}
.logo {
  width: 200px !important;
  float: left;
  margin-left: 10px;
}

.highlight {
  color: #8a459b !important;
  font-weight: bold !important;
}

.generate_story_icon {
  font-size: 20px;
  padding-bottom: 3px;
  margin-right: 2px;
}

.generate_story_icon {
  margin-left: 2px;
}

.logo_container {
  width: 300px !important;
  float: left;
  position: relative;
}

#about_dropdown {
  margin-left: 1px;
  color: black;
}

.nav-link {
  margin-left: 25px;
}

.basic-nav-dropdown,
.basic-nav-dropdown:focus,
.basic-nav-dropdown:hover {
  color: black !important;
}

.basic-navbar-nav {
  margin-left: 15px !important;
  margin-right: 2em;
}

.navbar_container {
  border-bottom: 3px solid #ebebeb;
  background-color: #ffffff;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  font-weight: 400;
  font-size: 15px;
}

.navbar_container_he {
  border-bottom: 3px solid #ebebeb;
  background-color: #ffffff;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  font-weight: "400";
  font-family: "Heebo", "Noto Naskh Arabic", serif;
  font-size: 15px;
}

.navbar-item {
  color: black !important;
  margin-left: 10px !important;
}

.navbar-light,
.navbar-nav,
.nav-link {
  color: black !important;
}

.navbar-item:hover {
  background-color: #eddff0;
}

.navbar-toggler {
  margin-left: 10px;
}

#formlang {
  margin-right: 15px;
  border: none;
}

#loginItem {
  white-space: nowrap;
  /*float: right;
  position: absolute;
  right:5px;*/
}

#headline {
  margin-left: 5px;
}

.fi {
  left: 20px;
}
.fi-il {
  left: 5px;
  margin-bottom: 2px;
}
.fi-sa {
  left: 5px;
  margin-bottom: 2px;
}

.btn-circle {
  width: 30px;
  height: 30px;
  font-size: 16px;
  line-height: 1.42857;
  padding-bottom: 8px;
  font-weight: var(--wbu-font-weight-regular);
}

.range {
  width: 120px;
  vertical-align: middle;
}

.btn-range {
  height: 30px;
  width: 150px;
  padding-bottom: 3px;
}

.buttonsRow {
  margin-bottom: 8px;
  margin-top: 5px;
  display: flex;
  position: absolute;
  float: left;
  opacity: 80%;
  z-index: 1;
}

.hoverable :hover {
  color: rgb(13, 110, 253);
  /*border: 0.2px solid grey;*/
}

.float {
  border-radius: 15px;
  border: 0.2px solid grey;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50px;
  text-align: center;
  font-family: var(
    --wbu-font-stack,
    HelveticaNeueW01-55Roma,
    HelveticaNeueW02-55Roma,
    HelveticaNeueW10-55Roma,
    Helvetica Neue,
    Helvetica,
    Arial,
    メイリオ,
    meiryo,
    ヒラギノ角ゴ pro w3,
    hiragino kaku gothic pro,
    sans-serif
  );
  -webkit-font-smoothing: antialiased;
  color: #2d3034;
  background: rgb(255, 255, 255);
  box-shadow: 0 1px 18px 0 rgb(0 0 0 / 8%), 0 6px 10px 0 rgb(0 0 0 / 10%),
    0 3px 5px -1px rgb(0 0 0 / 15%), 0 0 4px 0 rgb(0 0 0 / 10%);
}

.placholder {
  cursor: default !important;
}

a.delete_image_link:link {
  color: #8a459b;
  text-decoration: none;
  text-align: center;
}
a.delete_image_link:visited {
  color: #8a459b;
  text-decoration: none;
  text-align: center;
}

button.save_image_button + a.delete_image_link {
  display: block;
  margin: 8px 0;
}

.modal-title-he {
  margin-left: 317px;
}

.private_image{
    margin-top:10px;
}

.disabled_button{
    color: #b1b1b1 !important;
}

.search-and-button-container {
    display: flex;
    align-items: center; /* Vertically center the items */
    justify-content: space-between; /* Push the button to the left and search box to the right */
    width: 100%; /* Ensure the container takes the full width */
  }
  
  .search-and-button-container > .link_btn {
    /* You can adjust the button styling here */
  }
  
  .search-and-button-container > .WebImageSearch {
    /* You might want to ensure it doesn't shrink or grow disproportionately */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto; /* Adjust this to control the initial size of the search box */
    /* Ensure there's a minimum width so it doesn't get too small */
    min-width: 200px; /* Adjust based on your needs */
  }
  
  .web_results_credit{
    height: 16px;
    margin-left: 6px;
    margin-right:6px;
    padding-bottom:1px
  }

  .web_results_author{
    text-align: center;
    font-size: 9px;
    color:rgba(0, 0, 0, 0.5);
    margin-left:3px
  }
  .web_results_author a{
    text-decoration: none;
    color:rgba(0, 0, 0, 0.5);
  }
  
.book_card:hover{
    background-color: #efebf0;
    /*border:1px solid #8A459B !important;*/
    text-decoration: underline #efebf0 !important;
    border-radius: 30px !important;
}

.card-body:hover{
    text-decoration:underline #efebf0;
}

.book_card{
    padding:6px;
}

.book_offer_card:hover{
    background-color: #ebdfee;
    text-decoration: 'underline white',
}

.book_offer{
    background-color: #efebf0;
    border-radius: 22px;
    margin-right: 20px;
}

.book_card .trash-icon {
  display: none;
}

.book_card:hover .trash-icon {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.book_card:hover .trash-icon:hover {
  box-shadow: 0 0 5px #8A459B;
}

@media only screen and (max-width: 800px) {
    .book_offer{
        margin-right: 0px;
    }
}